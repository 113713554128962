exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktuelles-tsx": () => import("./../../../src/pages/aktuelles.tsx" /* webpackChunkName: "component---src-pages-aktuelles-tsx" */),
  "component---src-pages-biergarten-tsx": () => import("./../../../src/pages/biergarten.tsx" /* webpackChunkName: "component---src-pages-biergarten-tsx" */),
  "component---src-pages-dsgvo-tsx": () => import("./../../../src/pages/dsgvo.tsx" /* webpackChunkName: "component---src-pages-dsgvo-tsx" */),
  "component---src-pages-firmenfeiern-tsx": () => import("./../../../src/pages/firmenfeiern.tsx" /* webpackChunkName: "component---src-pages-firmenfeiern-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-reservieren-tsx": () => import("./../../../src/pages/reservieren.tsx" /* webpackChunkName: "component---src-pages-reservieren-tsx" */),
  "component---src-pages-speisekarte-tsx": () => import("./../../../src/pages/speisekarte.tsx" /* webpackChunkName: "component---src-pages-speisekarte-tsx" */),
  "component---src-pages-turkisches-restaurant-tsx": () => import("./../../../src/pages/turkisches-restaurant.tsx" /* webpackChunkName: "component---src-pages-turkisches-restaurant-tsx" */),
  "component---src-pages-uberuns-tsx": () => import("./../../../src/pages/uberuns.tsx" /* webpackChunkName: "component---src-pages-uberuns-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

